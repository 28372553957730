import { css, cx } from "@emotion/css";
import type { OctopusTheme } from "@octopusdeploy/design-system-tokens";
import { lightTheme, lightV2Theme } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren } from "react";
import React, { createContext, useMemo } from "react";
import { getTheme } from "./utils/getTheme";
export interface ThemeProps {
    themeName: ThemeName;
    isFullHeight?: boolean;
}
const DesignSystemThemeContext = createContext<OctopusTheme | undefined>(undefined);
export const DesignSystemThemeProvider = DesignSystemThemeContext.Provider;
export function useDesignSystemTheme() {
    const contextValue = React.useContext(DesignSystemThemeContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a DesignSystemThemeProvider");
    }
    return contextValue;
}
export function useIllustrationThemeName(): IllustrationThemeName {
    const currentTheme = useDesignSystemTheme();
    return currentTheme === lightTheme || currentTheme === lightV2Theme ? "light" : "dark";
}
export function Theme({ themeName, isFullHeight, children }: PropsWithChildren<ThemeProps>) {
    const theme: OctopusTheme = getTheme(themeName);
    const themeCssVariableProps = useMemo(() => {
        return Object.entries(theme).reduce((prev, [cssVariableName, value]) => {
            return {
                ...prev,
                [`--${cssVariableName}`]: value,
            };
        }, {});
    }, [theme]);
    return (<DesignSystemThemeProvider value={theme}>
            <div style={themeCssVariableProps} className={cx(themeContentStyles, { [fullHeightStyles]: isFullHeight })}>
                {children}
            </div>
        </DesignSystemThemeProvider>);
}
const fullHeightStyles = css({
    height: "100%",
});
const themeContentStyles = css({
    width: "100%",
});
type IllustrationThemeName = "light" | "dark";
export type ThemeName = "light" | "dark" | "lightV2" | "darkV2";
