import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import type { MouseEventHandler } from "react";
import React from "react";
import type { DesignSystemLinkHref, ShowLinkAsActive } from "../../routing/OctopusRoutingContext";
import type { AvatarProps } from "../Avatar/Avatar";
import { Avatar } from "../Avatar/Avatar";
import type { MenuTargetAriaAttributes } from "../Menu";
import type { NavigationSideBarButtonAriaAttributes } from "./NavigationSideBarIconButton";
import { NavigationSideBarIconButton } from "./NavigationSideBarIconButton";
import { NavigationSideBarTab } from "./NavigationSideBarTab";
interface SharedNavigationBarTab {
    /**
     * The type of vertical menu item.
     */
    type: "tab";
    /**
     * The icon for this link.
     */
    icon: React.ReactNode;
    /**
     * The link location.
     */
    href: DesignSystemLinkHref;
    showLinkAsActive?: ShowLinkAsActive;
    /**
     * The onClick handler associated with the tab.
     */
    onClick?: MouseEventHandler<Element>;
}
interface NavigationSideBarTabIconOnly extends SharedNavigationBarTab {
    /**
     * The required accessible name for this icon link.
     */
    accessibleName: string;
}
interface NavigationSideBarTabIconWithLabel extends SharedNavigationBarTab {
    /**
     * An optional accessible name for this icon link. If no accessible name is provided the label will be used.
     */
    accessibleName?: string;
    /**
     * The label for this icon link.
     */
    label: string;
}
type NavigationSideBarTabItem = NavigationSideBarTabIconOnly | NavigationSideBarTabIconWithLabel;
type NavigationSideBarCustomItem = {
    /**
     * The type of vertical menu item.
     */
    type: "custom";
    /**
     * A locally unique identifier for the custom item.
     */
    key: string;
    /**
     * A custom react element to use for the item.
     */
    content: React.ReactElement;
};
type NavigationSideBarIconButtonItem = {
    /**
     * The type of vertical menu item.
     */
    type: "icon-button";
    /**
     * An accessible name for the icon button.
     */
    accessibleName: string;
    /**
     * The icon to use for the icon button.
     */
    icon: React.ReactNode;
    /**
     * The onClick handler associated with the icon button.
     */
    onClick?: MouseEventHandler<Element>;
    /**
     * Additional ARIA menu attributes which can be attached to this icon button if it's
     * being used to show a menu.
     */
    menuAttributes?: NavigationSideBarButtonAriaAttributes;
};
export type NavigationSideBarItem = NavigationSideBarTabItem | NavigationSideBarIconButtonItem | NavigationSideBarCustomItem;
export type BottomNavigationSideBarItem = NavigationSideBarItem;
export type TopNavigationSideBarItem = NavigationSideBarTabIconWithLabel;
interface UserAvatarOptions {
    avatarProps: AvatarProps;
    onClick?: MouseEventHandler<Element>;
    menuTargetAttributes?: MenuTargetAriaAttributes;
    accessibleName: string;
}
export interface NavigationSideBarProps {
    topItems: ReadonlyArray<TopNavigationSideBarItem>;
    bottomItems: ReadonlyArray<BottomNavigationSideBarItem>;
    userAvatarOptions?: UserAvatarOptions;
    onLinkClicked?: () => void;
}
export function NavigationSideBar({ topItems, bottomItems, userAvatarOptions, onLinkClicked }: NavigationSideBarProps) {
    return (<div className={navigationSideBarStyles}>
            <NavigationSideBarTopItems items={topItems} onLinkClicked={onLinkClicked}/>
            <NavigationSideBarBottomItems items={bottomItems} userAvatarOptions={userAvatarOptions} onLinkClicked={onLinkClicked}/>
        </div>);
}
function NavigationSideBarTopItems({ items, onLinkClicked }: {
    items: ReadonlyArray<TopNavigationSideBarItem>;
    onLinkClicked?: () => void;
}) {
    return (<div className={navigationSideBarTopItemsStyles}>
            {items.map((x) => (<NavigationSideBarItemComponent key={x.label} item={x} onLinkClicked={onLinkClicked}/>))}
        </div>);
}
const navigationSideBarTopItemsStyles = css({
    display: "flex",
    flex: "1",
    flexDirection: "column",
    justifyItems: "stretch",
    rowGap: space[6],
});
interface NavigationSideBarBottomItemsProps {
    userAvatarOptions?: UserAvatarOptions;
    items: ReadonlyArray<BottomNavigationSideBarItem>;
    onLinkClicked?: () => void;
}
function NavigationSideBarBottomItems({ items, userAvatarOptions, onLinkClicked }: NavigationSideBarBottomItemsProps) {
    return (<div className={navigationSideBarBottomItemsStyles}>
            {items.map((x) => (<NavigationSideBarItemComponent key={getNavigationSideBarItemReactKey(x)} item={x} onLinkClicked={onLinkClicked}/>))}
            {userAvatarOptions && (<NavigationSideBarItemComponent key={userAvatarOptions.accessibleName} item={{ type: "icon-button", menuAttributes: userAvatarOptions.menuTargetAttributes, icon: <Avatar {...userAvatarOptions.avatarProps}/>, onClick: userAvatarOptions.onClick, accessibleName: userAvatarOptions.accessibleName }} onLinkClicked={onLinkClicked}/>)}
        </div>);
}
const navigationSideBarBottomItemsStyles = css({
    display: "flex",
    justifyItems: "stretch",
    flexDirection: "column",
    rowGap: space[4],
});
function getNavigationSideBarItemReactKey(item: NavigationSideBarItem): string {
    if (item.type === "custom") {
        return item.key;
    }
    if (isSideBarIconButton(item)) {
        return item.accessibleName;
    }
    return isSideBarItemTabWithLabel(item) ? item.label : item.accessibleName;
}
function isSideBarIconButton(item: NavigationSideBarItem): item is NavigationSideBarIconButtonItem {
    return item.type === "icon-button";
}
function isSideBarItemTabWithLabel(item: NavigationSideBarTabItem): item is NavigationSideBarTabIconWithLabel {
    return "label" in item && Boolean(item.label);
}
const navigationSideBarStyles = css({
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "stretch",
    width: "4.25rem",
    paddingTop: space[16],
    paddingBottom: space[16],
});
function NavigationSideBarItemComponent({ item, onLinkClicked }: {
    item: NavigationSideBarItem;
    onLinkClicked?: () => void;
}): React.ReactElement {
    const { type } = item;
    switch (type) {
        case "tab":
            const onClick: MouseEventHandler<Element> = (event) => {
                onLinkClicked?.();
                item.onClick?.(event);
            };
            return isSideBarItemTabWithLabel(item) ? (<NavigationSideBarTab icon={item.icon} href={item.href} accessibleName={item.accessibleName} showLinkAsActive={item.showLinkAsActive} label={item.label} onClick={onClick}/>) : (<NavigationSideBarTab icon={item.icon} href={item.href} accessibleName={item.accessibleName} showLinkAsActive={item.showLinkAsActive} onClick={onClick}/>);
        case "custom":
            return <CustomContent>{item.content}</CustomContent>;
        case "icon-button":
            return <NavigationSideBarIconButton accessibleName={item.accessibleName} icon={item.icon} onClick={item.onClick} {...item.menuAttributes}/>;
        default:
            exhaustiveCheck(type, `Unsupported navigation bar item type "${type}"`);
    }
}
function CustomContent({ children }: React.PropsWithChildren<{}>) {
    return <div className={customContentStyles}>{children}</div>;
}
const customContentStyles = css({
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
});
