import { injectGlobal, css } from "@emotion/css";
import { text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import * as React from "react";
import { Tooltip as TippyTooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
export type TooltipContent = string | ReactNode;
export type TooltipPosition = "right" | "left" | "top" | "bottom";
export interface TooltipProps {
    /** The content to be shown in the tooltip popper */
    content?: TooltipContent;
    /** The html element that will show a tooltip when user hovers over it */
    children?: React.ReactNode;
    /** The position that the tooltip popper shows based on the container element (children prop)*/
    position?: TooltipPosition;
    /**
     * Specifies which type of events will trigger a tooltip to show
     * More info can be found: https://github.com/tvkhoa/react-tippy
     */
    trigger?: "mouseenter" | "focus" | "click" | "manual";
    /** Just only use it if you want to show/hide tooltip popper manually. Usually, you don't need it*/
    open?: boolean;
    /**
     * Custom styles
     * @deprecated Please avoid using this. This property will be removed in the near future.
     */
    style?: React.CSSProperties;
    /**
     * Specifies how far away the tooltip is from its element, in pixel.
     * @deprecated Please avoid using this, and please contact BPE team if you really need this, it could be a good signal to improve our Tooltip component.
     */
    distance?: number;
    /**
     * Controls the display css property of the tooltip div.
     */
    display?: "block" | "inline-block";
}
export function Tooltip({ content, children, position = "top", open, trigger, style, distance, display }: TooltipProps) {
    return (<TippyTooltip className={tooltipContainerStyles} open={open} trigger={trigger} arrow={true} position={position} useContext={true} html={<span className={tooltipContentStyles}>{content}</span>} size="small" style={{ ...style, display: display ?? "inline-block" }} distance={distance} popperOptions={{
            modifiers: {
                preventOverflow: { boundariesElement: "window" },
            },
        }}>
            {children}
        </TippyTooltip>);
}
const tooltipContainerStyles = css({
    // react-tippy adds an empty div element immediately after the container when the useContext prop is true, the empty div causes extra space or line when the container is hovered,
    // therefore using the adjacent sibling selector here to remove the side effect.
    "+ div:empty": {
        display: "none",
    },
});
const tooltipContentStyles = css({
    backgroundColor: themeTokens.color.background.inverse.primary,
    color: themeTokens.color.text.inverse,
    font: text.tooltip.xSmall,
    // When Tooltip renders markdown, the Markdown component creates <p> tags. The style above is overridden by the markdown css,
    // so adding following block to apply our custom css when the tooltip content is using markdown.
    p: {
        backgroundColor: themeTokens.color.background.inverse.primary,
        color: themeTokens.color.text.inverse,
    },
});
// There is no way to gain more granular control of popper styles other than to leverage the class names attached to tippy-tooltip elements
injectGlobal({
    ".tippy-popper .tippy-tooltip": {
        backgroundColor: themeTokens.color.background.inverse.primary,
    },
    ".tippy-popper[x-placement^=\"right\"] .arrow-regular[x-arrow]": {
        borderRightColor: themeTokens.color.background.inverse.primary,
    },
    ".tippy-popper[x-placement^=\"left\"] .arrow-regular[x-arrow]": {
        borderLeftColor: themeTokens.color.background.inverse.primary,
    },
    ".tippy-popper[x-placement^=\"top\"] .arrow-regular[x-arrow]": {
        borderTopColor: themeTokens.color.background.inverse.primary,
    },
    ".tippy-popper[x-placement^=\"bottom\"] .arrow-regular[x-arrow]": {
        borderBottomColor: themeTokens.color.background.inverse.primary,
    },
});
