import { css, cx } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { useIsLargerThanIpadResolution } from "../../hooks/breakpoints";
import { MenuIcon } from "../Icon";
import { NavigationBarActions, type NavigationBarActionData } from "../NavigationBar/NavigationBarActions";
import { NavigationBarIconButton } from "../NavigationBar/NavigationBarIconButton";
interface GlobalLayoutTopNavBarProps {
    logo?: React.ReactElement;
    searchBar?: React.ReactElement;
    actions?: NavigationBarActionData[];
    onExpandMenu: () => void;
}
export function GlobalLayoutTopNavBar({ logo, searchBar, actions, onExpandMenu }: GlobalLayoutTopNavBarProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const actionsComponent = actions && (<NavBarActionsContainer>
            <NavigationBarActions items={actions}/>
        </NavBarActionsContainer>);
    if (isLargerThanIpad) {
        return (<nav className={cx(navBarStyles)}>
                <NavBarRow>
                    {logo}
                    {searchBar}
                    {actionsComponent}
                </NavBarRow>
            </nav>);
    }
    return (<nav className={cx(navBarStyles)}>
            <NavBarRow>
                {<NavigationBarIconButton icon={<MenuIcon />} accessibleName="Open navigation menu" onClick={onExpandMenu}/>}
                {logo}
                {actionsComponent}
            </NavBarRow>
            {searchBar && (<NavBarRow>
                    <div className={searchBarMobileContainerStyles}>{searchBar}</div>
                </NavBarRow>)}
        </nav>);
}
function NavBarRow({ children }: React.PropsWithChildren<{}>) {
    return <div className={cx(navBarRowStyles)}>{children}</div>;
}
function NavBarActionsContainer({ children }: React.PropsWithChildren<{}>) {
    return <div className={cx(navBarActionsContainerStyles)}>{children}</div>;
}
NavBarActionsContainer.displayName = "NavBarActionsContainer";
const navBarStyles = css({
    position: "relative",
    background: themeTokens.color.navigation.background.primary,
    color: themeTokens.color.navigation.link.text.default,
    fill: themeTokens.color.navigation.link.text.default,
    padding: space[16],
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: space[16],
});
const navBarRowStyles = css({
    display: "flex",
    //We need an explicit height in order to avoid items/actions from expanding
    //the navbar past what the design accounts for.
    height: "2.25rem",
    gap: space[16],
    overflow: "clip",
});
const searchBarMobileContainerStyles = css({
    flex: 1,
});
const navBarActionsContainerStyles = css({
    marginLeft: "auto",
});
