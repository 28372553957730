import { css } from "@emotion/css";
import MaterialMenu from "@material-ui/core/Menu";
import type { PopoverOrigin } from "@material-ui/core/Popover/Popover";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren, KeyboardEventHandler } from "react";
import React, { useCallback, useMemo } from "react";
import { flattenFragments } from "../../utils/flattenFragments";
export type MenuProps = (MenuState | {
    menuState: MenuState;
}) & CommonMenuProps;
export interface MenuState {
    isOpen: boolean;
    onClose: () => void;
    anchorElement: null | Element;
    menuId: string;
}
export interface CommonMenuProps {
    anchorOrigin?: MenuAnchorOrigin;
    onKeyDown?: KeyboardEventHandler;
    accessibleName: string;
}
export interface MenuAnchorOrigin {
    horizontal: "left" | "right";
    vertical: "top" | "bottom";
}
export function CustomMenu(props: PropsWithChildren<MenuProps>) {
    const { children, anchorOrigin, onKeyDown, accessibleName } = props;
    const { isOpen, onClose, anchorElement, menuId } = "menuState" in props ? props.menuState : props;
    const anchorOriginWithDefaults = useMemo<PopoverOrigin>(() => ({
        horizontal: anchorOrigin?.horizontal ?? "left",
        vertical: anchorOrigin?.vertical ?? "bottom",
    }), [anchorOrigin]);
    //Menus are generally removed from the usual flow of elements so mouse events like click / mouse down etc. are a bit
    //surprising to receive in containing elements such as overflow titles etc. as these can have unintended behaviors.
    const stopEventPropagation = useCallback((event: React.MouseEvent) => event.stopPropagation(), []);
    // Material UI's Menu implementation tries to find the first item in the menu, and align this first child to the anchor element.
    // By passing null to getContentAnchorEl, we opt out of this behaviour and let material-ui align the entire popover to the anchor element.
    // We need to do this in order to be able to provide the anchorOrigin prop, and material-ui logs errors if we don't do this.
    const getContentAnchorEl = null;
    // If we specify an onKeyDown prop (even if it is undefined), this will overwrite material-uis onkeydown handler for modals (i.e. escape handling)
    // It looks like this might be fixed in more recent versions of material-ui (https://github.com/mui-org/material-ui/blob/4323c7706b6b641dcc9ed51f00ff163e5f80966f/packages/mui-base/src/ModalUnstyled/ModalUnstyled.js#L210-L212)
    // Currently, this means that escape won't work for nested menus
    const onKeyDownProps = onKeyDown ? { onKeyDown } : {};
    // Material-ui Menus don't support fragments as children
    // This is so that they can inspect the props of their children in order to work out which one is selected
    // This is using a feature of menus (selection) that we don't use, so for our usages we can allow fragments
    const childrenWithoutFragments = flattenFragments(children);
    return (<>
            {/*We disable autoFocus here because this feature relies on
        material-ui's menu cloning its children and adding an autoFocus prop.
        This kind of coupling is brittle and limits the kinds of things that can be children of the menu.

        Instead of doing this, we think it better to opt-out of this feature
        and let the children of the menu control which child is automatically focused first.

        By using the SimpleMenuItems component as a child of the menu, this behaviour is automatically provided in a less brittle way.

        Focusing on an element inside the menu upon opening the menu is particularly important,
        because the keyboard interactivity that is implemented in material-ui's menu relies on a child being focused.

        Another important constraint here is that the material-ui menu renders a ul,
        and the immediate descendants of the ul must be focusable.
        This maps to the children that are passed in. Without this, keyboard navigation won't work.
        They also must have an explicit tabIndex property defined and can't rely on the browser defaults.
        */}
            {/*TODO: If we get a chance, it would be nice to render a popover that
        contains the CustomMenuList component instead of using Menu from material-ui.
        That way, if we want to change how the MenuList looks or behaves, we can change it in one place only.*/}
            <MaterialMenu id={menuId} open={isOpen} onClose={onClose} anchorEl={anchorElement} autoFocus={false} MenuListProps={{ component: "div", "aria-label": accessibleName, className: menuListStyles }} anchorOrigin={anchorOriginWithDefaults} getContentAnchorEl={getContentAnchorEl} onMouseDown={stopEventPropagation} onMouseUp={stopEventPropagation} onClick={stopEventPropagation} {...onKeyDownProps}>
                {childrenWithoutFragments}
            </MaterialMenu>
        </>);
}
const menuListStyles = css({
    "&.MuiList-root": {
        backgroundColor: themeTokens.color.background.primary.default,
    },
});
CustomMenu.displayName = "CustomMenu";
