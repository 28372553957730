import type { RefObject } from "react";
import { useEffect, useState } from "react";
export function useIsHovered(ref: RefObject<HTMLElement>) {
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        const container = ref.current;
        if (container) {
            const onMouseEnter = () => setIsHovered(true);
            const onMouseLeave = () => setIsHovered(false);
            container.addEventListener("mouseenter", onMouseEnter);
            container.addEventListener("mouseleave", onMouseLeave);
            return () => {
                container.removeEventListener("mouseenter", onMouseEnter);
                container.removeEventListener("mouseleave", onMouseLeave);
            };
        }
    }, [ref]);
    return isHovered;
}
