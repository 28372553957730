import { css } from "@emotion/css";
import { borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
const tableBorder = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
export const tableStyles = css({
    width: "100%",
    backgroundColor: themeTokens.color.background.primary.default,
    borderSpacing: 0,
    th: {
        padding: `${space[12]} ${space[16]}`,
        textAlign: "left",
        font: text.table.cell.default.medium,
        position: "relative",
        ":not(:last-child)": {
            ":after": {
                content: "''",
                position: "absolute",
                height: "50%",
                right: 0,
                top: "25%",
                borderRight: tableBorder,
            },
        },
    },
    td: {
        borderTop: tableBorder,
        font: text.table.cell.default.base,
        padding: `${space[8]} ${space[12]}`,
        verticalAlign: "middle",
    },
});
export const tableMobileStyles = css({
    display: "block",
    overflowX: "auto",
});
