import * as React from "react";
type BusyState = Promise<unknown> | boolean;
export function useIsBusy(busyState: BusyState | undefined | null) {
    const [isBusy, setIsBusy] = React.useState(false);
    const latestPromise = React.useRef<Promise<unknown> | undefined>();
    React.useEffect(() => {
        let effectIsActive = true;
        if (busyState instanceof Promise) {
            const awaitPromise = async () => {
                latestPromise.current = busyState;
                setIsBusy(true);
                try {
                    await busyState;
                }
                catch {
                    // We don't care about errors, we only care about the promise completing
                }
                if (effectIsActive && latestPromise.current == busyState) {
                    setIsBusy(false);
                }
            };
            awaitPromise();
            return () => {
                effectIsActive = false;
            };
        }
        else if (typeof busyState === "boolean") {
            setIsBusy(busyState);
            return;
        }
        else {
            setIsBusy(false);
            return;
        }
    }, [busyState]);
    return isBusy;
}
