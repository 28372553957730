import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
export const menuItemClasses = {
    root: "MuiMenuItem-root",
    button: "MuiListItem-button",
};
export const menuItemStyles = css({
    [`&.${menuItemClasses.root}`]: {
        color: themeTokens.color.text.primary,
        backgroundColor: themeTokens.color.background.primary.default,
        [`&.${menuItemClasses.button}`]: {
            "&:hover": {
                color: themeTokens.color.text.primary,
                backgroundColor: themeTokens.color.background.primary.hovered,
            },
            "&:focus": {
                color: themeTokens.color.text.primary,
                backgroundColor: themeTokens.color.background.primary.hovered,
            },
        },
    },
});
