import { css, cx } from "@emotion/css";
import { SwipeableDrawer } from "@material-ui/core";
import { themeTokens, space, borderRadius } from "@octopusdeploy/design-system-tokens";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useIsLargerThanIpadResolution } from "../../hooks/breakpoints";
import { isHTMLElement } from "../../utils";
import type { NavigationBarActionData } from "../NavigationBar/NavigationBarActions";
import { NavigationList } from "../NavigationList";
import type { NavigationListItem, NavigationListProps } from "../NavigationList";
import { NavigationSideBar, type BottomNavigationSideBarItem, type TopNavigationSideBarItem } from "../NavigationSideBar/NavigationSideBar";
import { ScrollArea } from "../ScrollArea";
import { GlobalLayoutTopNavBar } from "./GlobalLayoutTopNavBar";
export interface GlobalLayoutProps {
    topNavBar: {
        logo?: React.ReactElement;
        searchBar?: React.ReactElement;
        actions?: NavigationBarActionData[];
    };
    sideNavBar: {
        topItems: ReadonlyArray<TopNavigationSideBarItem>;
        bottomItems: ReadonlyArray<BottomNavigationSideBarItem>;
    };
    areaNavItems: ReadonlyArray<NavigationListItem>;
    isAreaNavPanelVisible: boolean;
    isAreaNavPanelCollapsed: boolean;
    onAreaNavPanelCollapseToggled: (isCollapsed: boolean) => void;
    isInPageNavVisible: boolean;
    children: React.ReactNode;
    onSideBarClickAway?: () => void;
}
export function GlobalLayout({ topNavBar, sideNavBar, areaNavItems, isAreaNavPanelVisible, isAreaNavPanelCollapsed, onAreaNavPanelCollapseToggled, isInPageNavVisible, onSideBarClickAway, children }: GlobalLayoutProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const [isSideMenuExpanded, setIsSideMenuExpanded] = useState(false);
    const [topNavBarContainerHeight, setTopNavBarContainerHeight] = useState(0);
    const openSideMenu = () => setIsSideMenuExpanded(true);
    const closeSideMenu = () => setIsSideMenuExpanded(false);
    return (<div className={rootProps}>
            <GlobalLayoutTopNavBarContainer onHeightChanged={(height) => setTopNavBarContainerHeight(height)}>
                <GlobalLayoutTopNavBar {...topNavBar} onExpandMenu={openSideMenu}/>
            </GlobalLayoutTopNavBarContainer>
            <div className={mainContainerProps}>
                <GlobalLayoutSideContainer topOffset={topNavBarContainerHeight} isDrawer={!isLargerThanIpad} isExpanded={isSideMenuExpanded} onClose={closeSideMenu} onOpen={openSideMenu} onClickAway={onSideBarClickAway}>
                    <NavigationSideBar topItems={sideNavBar.topItems} bottomItems={sideNavBar.bottomItems} onLinkClicked={closeSideMenu}/>
                    <GlobalAreaNavListPanel listItems={isAreaNavPanelVisible ? areaNavItems : []} isAreaNavPanelCollapsed={isAreaNavPanelCollapsed} onAreaNavPanelCollapseToggled={onAreaNavPanelCollapseToggled} onLinkClicked={closeSideMenu} isInPageNavVisible={isInPageNavVisible}/>
                </GlobalLayoutSideContainer>
                <GlobalLayoutContent isRoundedCorner={(isAreaNavPanelVisible && isAreaNavPanelCollapsed) || (isAreaNavPanelVisible && isInPageNavVisible) || areaNavItems.length === 0}>{children}</GlobalLayoutContent>
            </div>
        </div>);
}
function GlobalLayoutTopNavBarContainer({ onHeightChanged, children }: React.PropsWithChildren<{
    onHeightChanged: (height: number) => void;
}>) {
    const containerRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        const container = containerRef.current;
        if (!container)
            return;
        const resizeObserver = new ResizeObserver(() => {
            onHeightChanged(container.clientHeight);
        });
        resizeObserver.observe(container);
        return () => {
            resizeObserver.disconnect();
        };
    }, [onHeightChanged]);
    return <div ref={containerRef}>{children}</div>;
}
type GlobalLayoutSideContainerProps = {
    isDrawer: false;
    topOffset: number;
    onClickAway?: () => void;
} | {
    isDrawer: true;
    isExpanded: boolean;
    onClose: () => void;
    onOpen: () => void;
    onClickAway?: () => void;
};
function GlobalLayoutSideContainer({ onClickAway, children, ...props }: React.PropsWithChildren<GlobalLayoutSideContainerProps>) {
    const containerRef = useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const container = containerRef.current;
        if (container === null)
            return;
        const onPointerDown = (event: FocusEvent | PointerEvent) => {
            if (isHTMLElement(event.target) && !container.contains(event.target) && onClickAway) {
                onClickAway();
            }
        };
        window.addEventListener("pointerdown", onPointerDown);
        return () => {
            window.removeEventListener("pointerdown", onPointerDown);
        };
    }, [onClickAway]);
    if (!props.isDrawer) {
        return (<div ref={containerRef} className={cx(globalLayoutSideContainerStyles)}>
                {children}
            </div>);
    }
    return (<SwipeableDrawer open={props.isExpanded} onClose={props.onClose} onOpen={props.onOpen}>
            <div ref={containerRef} className={globalLayoutSideContainerDrawerStyles}>
                {children}
            </div>
        </SwipeableDrawer>);
}
const globalLayoutSideContainerStyles = css({
    display: "flex",
    position: "relative", // Needed when AreaNavBar goes into overlay/absolute mode to allow scrolling.
});
const globalLayoutSideContainerDrawerStyles = css({
    display: "flex",
    backgroundColor: themeTokens.color.navigation.background.primary,
    height: "100%",
});
type GlobalAreaNavListPanelProps = NavigationListProps & {
    isAreaNavPanelCollapsed: boolean;
    isInPageNavVisible: boolean;
    onAreaNavPanelCollapseToggled: (isCollapsed: boolean) => void;
};
function GlobalAreaNavListPanel({ isAreaNavPanelCollapsed, isInPageNavVisible, onAreaNavPanelCollapseToggled, ...props }: GlobalAreaNavListPanelProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const onCollapsedOverlayClick = React.useCallback(() => {
        onAreaNavPanelCollapseToggled(false);
    }, [onAreaNavPanelCollapseToggled]);
    if (props.listItems.length === 0) {
        return null;
    }
    const isOverlayExpanded = !isLargerThanIpad ? false : isInPageNavVisible && !isAreaNavPanelCollapsed;
    return (<>
            {isOverlayExpanded && <div className={absolutePadder}></div>}
            <div onClick={!isOverlayExpanded ? onCollapsedOverlayClick : undefined} className={cx(globalAreaNavListPanelCommonStyles, isOverlayExpanded ? globalAreaNavListPanelAbsoluteStyles : globalAreaNavListPanelRelativeStyles, {
            [globalAreaNavPanelCollapsedStyles]: isAreaNavPanelCollapsed,
        })}>
                <AreaNavPanelCollapsedToggle isPanelCollapsed={isAreaNavPanelCollapsed} onToggle={() => onAreaNavPanelCollapseToggled(!isAreaNavPanelCollapsed)}/>
                {!isAreaNavPanelCollapsed && <NavigationList {...props}/>}
            </div>
        </>);
}
// This helps us give the illusion as we transition AreaNavListPanel from an inline to an overlay, as
// when it's collapsed, it takes up 2rem of space, which we need to visually account for when we switch
// into overlay mode.
const absolutePadder = css({
    display: "none", // Hiding the column view, to let us first experiment with less noise.
    // width: "1.5rem",
});
const globalAreaNavPanelCollapsedStyles = css({
    display: "none", // Hiding the column view, to let us first experiment with less noise.
    // width: "1.5rem",
    // backgroundColor: themeTokens.color.background.primary.transparent,
    // [":hover"]: {
    //     background: themeTokens.color.background.primary.hovered,
    //     cursor: "pointer",
    // },
});
const toggleAreaNavPanelId = "toggleAreaNavPanel";
const globalAreaNavListPanelCommonStyles = css({
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    boxShadow: themeTokens.shadow.layoutPrimary,
    borderTopLeftRadius: borderRadius.medium,
    overflow: "hidden",
    "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
    },
    [`:hover #${toggleAreaNavPanelId}`]: {
        opacity: "0.4",
        visibility: "visible",
    },
});
const globalAreaNavListPanelAbsoluteStyles = css({
    position: "absolute",
    left: "4.25rem",
    height: "100%",
    boxShadow: themeTokens.shadow.large,
    zIndex: 1,
    // Need a solid background when we're in absolute mode since we're overlaying other content.
    backgroundColor: themeTokens.color.background.primary.default,
});
const globalAreaNavListPanelRelativeStyles = css({
    position: "relative",
});
function GlobalLayoutContent({ isRoundedCorner, children }: React.PropsWithChildren<{
    isRoundedCorner: boolean;
}>) {
    return (<main className={cx(globalLayoutContentStyles, { [globalLayoutContentWithoutAreaNavPanelStyles]: isRoundedCorner })}>
            <ScrollArea>{children}</ScrollArea>
        </main>);
}
interface AreaNavPanelCollapsedToggleProps {
    isPanelCollapsed: boolean;
    onToggle: () => void;
}
function AreaNavPanelCollapsedToggle({ isPanelCollapsed, onToggle }: AreaNavPanelCollapsedToggleProps) {
    const handleToggle = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        onToggle();
    }, [onToggle]);
    return (<div id={toggleAreaNavPanelId} className={toggleStyles} onClick={handleToggle}>
            <em className={isPanelCollapsed ? "fa-solid fa-angle-double-right" : "fa-solid fa-angle-double-left"}/>
        </div>);
}
const toggleStyles = css({
    position: "absolute",
    opacity: "0",
    visibility: "hidden",
    transition: "opacity 300ms",
    transitionTimingFunction: "ease-in",
    right: "0",
    top: space["4"],
    padding: "0 0.25rem",
    cursor: "pointer",
    ".MuiIconButton-root": {
        color: themeTokens.color.icon.secondary,
    },
});
const globalLayoutContentStyles = css({
    flex: 1,
    isolation: "isolate",
    boxShadow: themeTokens.shadow.layoutPrimary,
    background: themeTokens.color.background.supplemantary.default,
    // Prevents content from overflowing the width of this container
    minWidth: 0,
});
const globalLayoutContentWithoutAreaNavPanelStyles = css({
    borderTopLeftRadius: borderRadius.medium,
});
const rootProps = css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    backgroundColor: themeTokens.color.background.secondary.default,
});
const mainContainerProps = css({
    flex: 1,
    display: "flex",
    minHeight: 0,
});
