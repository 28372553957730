import { css, cx } from "@emotion/css";
import { Fade, Popper } from "@material-ui/core";
import { useLayoutEffect, useState } from "react";
import * as React from "react";
import { createPortal } from "react-dom";
export interface SpotlightOverlayProps {
    target: HTMLDivElement | undefined;
    onOverlayClick: () => void;
    show: boolean;
    position: "absolute" | "fixed";
}
export function SpotlightOverlay(props: SpotlightOverlayProps) {
    const { target, onOverlayClick, show } = props;
    const [targetSize, setTargetSize] = useState<number[]>([0, 0]);
    useLayoutEffect(() => {
        if (!target)
            return;
        function updateSize() {
            if (!target)
                return;
            const elementRect = target.getBoundingClientRect();
            const { width, height } = elementRect;
            setTargetSize([width, height]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, [target]);
    const padding = 8;
    const [targetWidth, targetHeight] = targetSize;
    const styles = {
        container: css({
            width: "100%",
            zIndex: "100",
            backgroundColor: "#00000051",
            mixBlendMode: "hard-light",
        }),
        containerAbsolutePosition: css({
            position: "absolute",
            top: 0,
            bottom: 0,
        }),
        containerFixedPosition: css({
            position: "fixed",
            top: 0,
            height: "100vh",
        }),
        spotlight: css({
            height: Math.round(targetHeight) + padding * 2,
            width: Math.round(targetWidth) + padding * 2,
            backgroundColor: "grey",
            borderRadius: "16px",
        }),
    };
    if (!target)
        return null;
    const isFixedPosition = props.position === "fixed";
    return (<>
            {createPortal(<Fade in={show} timeout={500}>
                    <div className={cx(styles.container, { [styles.containerAbsolutePosition]: !isFixedPosition, [styles.containerFixedPosition]: isFixedPosition })} onClick={onOverlayClick}>
                        {target && (<Popper popperOptions={{ positionFixed: isFixedPosition }} placement="top-start" disablePortal open={show} anchorEl={target} modifiers={{
                    flip: {
                        enabled: false,
                    },
                    offset: { enabled: true, offset: `-${padding}, -100% - ${padding}` },
                    preventOverflow: { enabled: false },
                    hide: { enabled: false },
                }}>
                                <div className={styles.spotlight}></div>
                            </Popper>)}
                    </div>
                </Fade>, document.body)}
        </>);
}
